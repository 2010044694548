import classNames from 'classnames';

import BpkImage from '@skyscanner/backpack-web/bpk-component-image';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text/src/BpkText';

import Author from '../Author';

import type {
  PageHeaderBaseProps,
  PageHeaderHeroProps,
  PageHeaderProps,
} from '@skyscanner-internal/falcon-common-types/types/PageHeaderProps';

import STYLE from './PageHeader.module.scss';

const HeroImage = ({ height, source, width }: PageHeaderHeroProps) => (
  <BpkImage
    altText="" // NOTE: Hero is just a decorative image so no altText
    aspectRatio={width / height}
    className={STYLE.HeroImage}
    src={source}
  />
);

type SubTitleProps = {
  children: string;
  tagName: 'span' | 'p';
};

const SubTitle = ({ children, tagName }: SubTitleProps) => (
  <BpkText
    className={STYLE.Subtitle}
    tagName={tagName}
    textStyle={TEXT_STYLES.editorial2}
  >
    {children}
  </BpkText>
);

const ColumnTitle = ({ title }: PageHeaderBaseProps) => (
  <div className={STYLE.Header}>
    <BpkText className={STYLE.Title} tagName="h1" textStyle={TEXT_STYLES.hero4}>
      {title}
    </BpkText>
  </div>
);

const EyebrowAndInHeroTitles = ({
  leadingSub,
  subH1,
  subtitle,
  title,
}: PageHeaderBaseProps) => {
  const showLeadingSub = leadingSub && subtitle;
  const showTrailingSub = !leadingSub && subtitle;

  if (subH1) {
    return (
      <BpkText tagName="h1" className={STYLE.Header}>
        {showLeadingSub && (
          <>
            <SubTitle tagName="span">{subtitle}</SubTitle>{' '}
          </>
        )}
        <BpkText
          className={classNames(STYLE.Title, {
            [STYLE.TitleTopMargin]: showLeadingSub,
            [STYLE.TitleBottomMargin]: showTrailingSub,
          })}
          tagName="span"
          textStyle={TEXT_STYLES.hero2}
        >
          {title}
        </BpkText>
        {showTrailingSub && (
          <>
            {' '}
            <SubTitle tagName="span">{subtitle}</SubTitle>
          </>
        )}
      </BpkText>
    );
  }

  return (
    <>
      {showLeadingSub && <SubTitle tagName="p">{subtitle}</SubTitle>}
      <BpkText
        className={classNames(STYLE.Title, {
          [STYLE.TitleTopMargin]: showLeadingSub,
          [STYLE.TitleBottomMargin]: showTrailingSub,
        })}
        tagName="h1"
        textStyle={TEXT_STYLES.hero2}
      >
        {title}
      </BpkText>
      {showTrailingSub && <SubTitle tagName="p">{subtitle}</SubTitle>}
    </>
  );
};

type InHeroContainerProps = {
  image: React.ReactNode;
  text: React.ReactNode;
};

const ColumnContainer = ({ image, text }: InHeroContainerProps) => (
  <div className={STYLE.ColumnLayout}>
    <div className={STYLE.TextContainer}>
      <div className={STYLE.FixedWidth}>{text}</div>
    </div>
    <div className={STYLE.ImageContainer}>
      <div className={STYLE.FixedWidth}>{image}</div>
    </div>
  </div>
);

const InHeroContainer = ({ image, text }: InHeroContainerProps) => (
  <div className={STYLE.ImageContainer}>
    {image}
    <div className={STYLE.TextContainer}>{text}</div>
  </div>
);

type EyebrowContainerProps = {
  image?: React.ReactNode;
  text: React.ReactNode;
};

const EyebrowContainer = ({ image = null, text }: EyebrowContainerProps) => (
  <>
    <div className={STYLE.TextContainer}>
      <div className={STYLE.FixedWidth}>{text}</div>
    </div>

    {image && (
      <div className={STYLE.ImageContainer}>
        <div className={STYLE.FixedWidth}>{image}</div>
      </div>
    )}
  </>
);

const PageHeader = ({
  author,
  headerLayout = 'Eyebrow',
  hero,
  leadingSub = false,
  subH1 = false,
  subtitle = '',
  title,
  withUnderlay,
}: PageHeaderProps) => {
  const getContent = () => {
    if (!hero) {
      return (
        <EyebrowContainer
          text={
            <EyebrowAndInHeroTitles
              leadingSub={leadingSub}
              subH1={subH1}
              subtitle={subtitle}
              title={title}
            />
          }
        />
      );
    }

    if (headerLayout === 'Column') {
      return (
        <ColumnContainer
          text={<ColumnTitle title={title} />}
          image={<HeroImage {...hero} height={528} width={1224} />}
        />
      );
    }

    if (headerLayout === 'InHero') {
      return (
        <InHeroContainer
          text={
            <EyebrowAndInHeroTitles
              leadingSub={leadingSub}
              subH1={subH1}
              subtitle={subtitle}
              title={title}
            />
          }
          image={<HeroImage {...hero} height={528} width={1224} />}
        />
      );
    }

    return (
      <EyebrowContainer
        text={
          <EyebrowAndInHeroTitles
            leadingSub={leadingSub}
            subH1={subH1}
            subtitle={subtitle}
            title={title}
          />
        }
        image={<HeroImage {...hero} height={528} width={1224} />}
      />
    );
  };

  const classNameList = [
    STYLE.PageHeader,
    STYLE[`PageHeader__${headerLayout}`],
  ];

  return (
    <>
      <div className={classNames(classNameList)}>
        <div className={STYLE.HeaderContent}>{getContent()}</div>
        {!hero &&
          (withUnderlay ? (
            <div className={STYLE.NoHeroUnderlay} />
          ) : (
            <div className={STYLE.NoHeroSpacer} />
          ))}
      </div>
      {headerLayout === 'Column' && (
        <div
          className={classNames(
            STYLE.AuthorContainer,
            !author && STYLE['AuthorContainer--no-author'],
          )}
        >
          <div className={STYLE.FixedWidth}>
            {author && <Author {...author} />}
          </div>
        </div>
      )}
    </>
  );
};

export default PageHeader;
